import React from "react"
import { Location } from '@reach/router'
import Hero from "../components/hero-cannabis"
import Content from "../components/content-cannabis"

import "../assets/index.css"
import Layout from "../layouts/layout"


export default () => (
    <Layout title="Cannabis | Ladd Partners"> 
        <Hero/>

        <Location>
            {({ location }) => {
                return  <><Content location={{ location }} /></>
            }}
        </Location>
    </Layout>
)
